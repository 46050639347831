import React, { useEffect } from 'react';

const TARGET =
  'https://gemfinance.zendesk.com/hc/en-nz/articles/29400351417105-How-do-I-apply-for-a-Balance-Transfer-offer';

function ZendeskCreditCardChangeRedirectPage({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace(TARGET);
    }
  }, []);

  return <></>;
}

export default ZendeskCreditCardChangeRedirectPage;